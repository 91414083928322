<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="9"></ucMenu>
					<div class="right_con">
						<div class="rc_main" v-loading="isLoading">
							<p class="fb">上架策略</p>
							<div class="totals_btn">
								<el-button plain type="primary" size="small" @click="strategryOpen(0)">新增策略</el-button>
								<el-button plain size="small" @click="strategryDel">删除策略</el-button>
							</div>
							<el-table class="rc_table" highlight-current-row @selection-change="selection"  row-key="id" :data="list" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
								<el-table-column align="center" type="selection" width="50"></el-table-column>
								<el-table-column align="center"  property="name" label="策略名称" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="date" label="创建时间" show-overflow-tooltip width=""></el-table-column>
								<el-table-column align="center"  property="scope" label="是否默认" show-overflow-tooltip width="">
									<template slot-scope="scope">
										<span style="margin-left:6px">{{scope.row.is_default=='1'?'是':'否'}}</span>
									</template>
								</el-table-column>
								<el-table-column label="操作" align="center" width="180">
									<template slot-scope="scope">
										<el-button size="mini" type="primary" @click="strategryOpen(scope.row)" >修改</el-button>
										<el-button size="mini" @click="strategryDel(scope.row)" >删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" v-if="page_data.total>page_data.limit" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
		<!-- 新增/修改策略 -->
		<el-dialog :title="strategy?'编辑':'新增'" :visible.sync="formDialog" width="500px" @close='closeMask'>
			<el-form label-width="100px" :model="strategy" class="opts_line" v-if="strategy">
				<el-form-item label="策略名称">
					<el-input size="small" v-model="strategy.name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="价格公式" required>
					<div class="con">
						<p class="t">原价格 x</p>
						<el-input class="w80 ml8" v-model="strategy.coefficient"></el-input>
						<el-select class="w80 ml8" v-model="strategy.type">
							<el-option label="+" :value="1">+</el-option>
							<el-option label="-" :value="2">-</el-option>
						</el-select>
						<el-input class="w80 ml8" v-model="strategy.money"></el-input>
						<p class="t ml8">元</p>
					</div>
				</el-form-item>
				<el-form-item label="分角设置" required>
					<div class="con">
						<el-radio-group v-model="strategy.set_fenjiao">
							<el-radio :label="item.type" v-for="(item,index) in strategy.fenjiao" :key="index">{{item.name}}</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item label="铺货方式" required>
					<div class="con">
						<el-radio-group v-model="strategy.puhuo_type">
							<el-radio :label="1">上架</el-radio>
							<el-radio :label="2">放入仓库</el-radio>
							<el-radio :label="3">放入草稿箱</el-radio>
						</el-radio-group>
					</div>
				</el-form-item>
				<el-form-item label="是否默认">
					<el-switch v-model="strategy.is_default" :active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='closeMask'>取消</el-button>
				<el-button size="small" type="primary" class="title" @click="saveEdit()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import { Loading } from 'element-ui';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import Pagination from '../../components/pagination/pagination';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import common from '../../assets/js/utils/common.js';
export default {
	name: "ucenterEmployeeManage",
	components:{
		myHead,myFoot,ucMenu,Pagination
	},
	data() {
		return {
			isLoading: false,
			searchData:{},
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			
			formDialog: false,
			strategy: null,
			init_strategy: null,
			list:[],
			roleList:[],
			ids:[],
		}
	},
	created() {
		this.getList()
	},
	methods: {
		...mapActions({
			getCelveData: "ucenter/getCelveData",
			saveCelveData: "ucenter/saveCelveData",
			opDelCelve: "ucenter/opDelCelve",
		}),
		strategryOpen(row){//打开策略弹窗
			if(row){
				this.strategy = row
			}else{
				this.strategy = this.init_strategy
			}
			this.formDialog = true
		},
		strategryDel(row){//策略删除
			this.$confirm('确定操作吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let ids = row.id ? row.id : this.ids.join(',')
				this.opDelCelve({data:{id:ids},success:(res)=>{
					if(res.code==200){
						// console.log('del',res)
						this.$message({type: 'success',message: res.msg})
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.isLoading = false
				}})
			}).catch(() => {})
		},
		getList(showLoading = true){//获取策略列表
			if(showLoading) this.isLoading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			this.getCelveData({data:param,success:(res)=>{
				if(res.code==200){
					let data = res.data.data
					for(let i in data){
						data[i].date = common._date(data[i].createtime,'Y-m-d H:i:s')
					}
					this.list = data
					this.init_strategy = res.data.init_strategy
					this.strategy = res.data.init_strategy
					this.page_data.total = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.isLoading = false
			}})
		},
		closeMask(){//关闭弹窗
			this.strategy = this.init_strategy
			this.formDialog = false
		},
		saveEdit(){
			if(!this.strategy.name){
				this.$message({type: 'info',message: "请输入策略名称！"})
				return false
			}
			if(this.strategy.coefficient<1){
				this.$message({type: 'info',message: "系数不能小于1！"})
				return false
			}
			let param = this.strategy
			this.saveCelveData({data:param,success:(res)=>{
				if (res.code === 200) {
					// console.log('save celve',res)
					this.$message({
						message: res.msg,
						type: 'success',
					});
					this.formDialog = false
					this.getList()
				} else {
					this.$message({
						message: res.msg,
						type: 'error',
					});
				}
				this.isLoading = false
				
			}})
		},
		selection(selection) {
			this.ids = selection.map(item => item.id)
		},
	}
}

</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 28px 0 40px;display: flex;justify-content: space-between;flex: 1;min-height: 500px;}
.right_con{width: 980px;background: #fff;}
.rc_table{width: 100%;margin-top: 20px}
.rc_main{padding: 36px 40px 46px;}
.select_wrap{margin-top: 24px;display: flex;flex-wrap: wrap;}
.select_wrap .mr{margin-right: 15px;}
.select_wrap .w290{width: 290px;margin-top: 14px;margin-right: 15px;position: relative;}
.select_wrap .w290:nth-child(3n){margin-right: 0;}
.el-cascader{line-height: 34px;}
.select_wrap .label_title{width: 76px;height: 100%;font-size: 12px;color: #999;display: flex;align-items: center;padding-left: 6px;}
.totals_btn{margin-top: 14px;display: flex;align-items: center;}
.tree_checkall{margin-top: 12px;}
.tree_box{padding: 10px;width: 100%;border: 1px solid #DCDFE6;border-radius: 4px;box-sizing: border-box;max-height: 250px;overflow: hidden auto;margin-top: 12px;}
.tree_box::-webkit-scrollbar{width:4px;}
.tree_box::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
.tree_box::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}
.w100{width: 100%;}


.opts_line .txt {
		width: 120px;
		text-align: right;
		font-size: 14px;
		
	}

	.opts_line .con {
		width: 90%;
		display: flex;
		align-items: center;
		height: 40px;
	}

	.opts_line .con .t {
		font-size: 14px;
		white-space: nowrap;
		margin:0 10px;
	}

</style>
<style>
.el-cascader{width: 100%;}
.select_wrap .el-button{margin-top: 14px;}
.select_wrap .el-input--prefix .el-input__inner,.select_wrap .el-cascader .el-input .el-input__inner{padding-left: 76px !important;height: 34px;line-height: 34px;font-size: 12px !important;}
.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
.el-select-dropdown__item.selected,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{color: var(--red);}
.select_wrap .el-input--prefix .el-input__inner:focus{border-color: var(--red);}
.el-input__prefix, .el-input__suffix{display: flex;align-items: center;}
.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}
.totals_btn .el-button--primary.is-plain{border-color: #fff;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus,.el-input.is-focus .el-input__inner,.el-input .el-input__inner:focus,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}

.el-button:focus, .el-button:hover{border-color: var(--red);color: var(--red);background:#fff;}
.el-button--primary{background: var(--red) !important;color: #fff !important;border-color: #fff;}
.el-button--primary:focus, .el-button:hover{border-color: var(--red);}
.el-loading-spinner .el-loading-text{color:var(--red);}
.el-loading-spinner .path{stroke: var(--red);}
.rc_table.el-table th.el-table__cell>.cell{padding: 0 14px;}
</style>